@import 'bgm.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.head-title {
  font-size: calc(1.325rem + 0.9vw);
}
body {
  background-color: #fff;
}
@media only screen and (min-width: 1024px) {
  body {
    background-color: #f5f5f5;
  }
}
.container {
  width: 80%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
.createat {
  font-size: 0.75rem;
  color: gray;
}
.like {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}
.url {
  position: absolute;
  right: 1rem;
  bottom: 2rem;
}
#URLModal {
  display: none;
}
.profileSpaceContainer {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 10px;
}
.profileSpaceItem {
  width: calc(100% / 3);
  padding: 10px;
}
.imgBox {
  aspect-ratio: 1/1;
  background-size: cover;
}
.itemBtn {
  padding: 10px;
}
.spaces {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
}
.space {
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* input font size 지정 */
.inputFontSize {
  font-size: 12px;
}

/* 버그 잡는 용도 */

.bt1 {
  border: 1px solid red;
}

.section {
  height: calc(100% - 58px - 1.25rem);
}

.search-result {
  height: calc(100% - 41px - 1.5rem);
  display: block;
  overflow: auto;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.error-body {
  background-color: #f7ebeb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 95vh;
}
.error-form {
  border-radius: 14px;
  background-color: white;
  padding: 1rem 2rem 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: left;
  width: 325px;
  height: fit-content;
  overflow-y: scroll;
}
.error-message {
  font-size: 14px;
  padding: 10px;
  color: gray;
}
.error-btn {
  color: white;
  background-color: #db1f72;
  font-size: 12px;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 10px;
}
.alarm-modal {
  position: absolute;
  left: calc(50% - 2.5rem);
  display: flex;
  flex-direction: column;
  top: 60px;
  height: 400px;
  width: 500px;
  z-index: 999999;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.alarm-modal:after {
  content: ' ';
  position: absolute;
  z-index: 9999;
  left: 356px;
  top: -14px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid white;
}

.setting-bar {
  padding-left: 20px;
  width: 242px;
  border-right: 1px solid #e0e0e0;
  border-radius: 5px;
}
.setting-label {
  flex-basis: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.setting-input {
  flex-basis: 60%;
}
