.bgm-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: relative;
  width: 100%;
}
.bgm-item {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-items: stretch;
  padding: 4px 0px;
  position: relative;

  width: 100%;

  /* Inside auto layout */
  flex: none;
  order: 0;
}
.bgm-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 0px 3px 8px;
  justify-content: center;
  flex-grow: 1;
}
.bgm-name {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.bgm-prod {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 350;
  font-size: 12px;
  line-height: 16px;
  color: #808080;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.bgm-name-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;
  gap: 2px;
  flex-grow: 1;
}
#duration {
  position: absolute;
  right: 0;
}
.bgm-btn {
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  outline: none;
  width: 24x;
  height: 24px;
  float: left;
}
#audio-player-container {
  --seek-before-width: 0%;
  --volume-before-width: 100%;
  --buffered-width: 0%;
  position: relative;
  margin: 100px 2.5% auto 2.5%;
  width: 95%;
  max-width: 500px;
  height: 132px;
  background: #fff;
}
#audio-player-container::before {
  position: absolute;
  content: '';
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  left: -2px;
  top: -2px;
  background: linear-gradient(to left, #007db5, #ff8a00);
  z-index: -1;
}
/* p {
    position: absolute;
    top: -18px;
    right: 5%;
    padding: 0 5px;
    margin: 0;
    font-size: 28px;
    background: #fff;
} */
/* path {
    stroke: #007db5;
} */
.time {
  display: inline-block;
  width: 37px;
  text-align: center;
  font-size: 12px;
  flex-shrink: 0;
}
/* output {
    display: inline-block;
    width: 32px;
    text-align: center;
    font-size: 20px;
    margin: 10px 2.5% 0 5%;
    float: left;
    clear: left;
} */
#volume-slider {
  margin: 10px 2.5%;
  width: 58%;
}
#volume-slider::-webkit-slider-runnable-track {
  background: rgba(0, 125, 181, 0.6);
}
#volume-slider::-moz-range-track {
  background: rgba(0, 125, 181, 0.6);
}
#volume-slider::-ms-fill-upper {
  background: rgba(0, 125, 181, 0.6);
}
#volume-slider::before {
  width: var(--volume-before-width);
}
#mute-icon {
  margin: 0 2.5%;
}
.bgm-input {
  position: relative;
  -webkit-appearance: none;
  width: 48%;
  margin: 0;
  padding: 0;
  height: 19px;
  margin: 30px 2.5% 20px 2.5%;
  float: left;
  outline: none;
}
.bgm-input::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: linear-gradient(
    to right,
    rgba(0, 125, 181, 0.6) var(--buffered-width),
    rgba(0, 125, 181, 0.2) var(--buffered-width)
  );
}
.bgm-input::before {
  position: absolute;
  content: '';
  top: 8px;
  left: 0;
  width: var(--seek-before-width);
  height: 3px;
  background-color: #007db5;
  cursor: pointer;
}
.bgm-input::-webkit-slider-thumb {
  position: relative;
  -webkit-appearance: none;
  box-sizing: content-box;
  border: 1px solid #007db5;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  margin: -7px 0 0 0;
}
.bgm-input:active::-webkit-slider-thumb {
  transform: scale(1.2);
  background: #007db5;
}
.bgm-input::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: linear-gradient(
    to right,
    rgba(0, 125, 181, 0.6) var(--buffered-width),
    rgba(0, 125, 181, 0.2) var(--buffered-width)
  );
}
.bgm-input::-moz-range-progress {
  background-color: #007db5;
}
.bgm-input::-moz-focus-outer {
  border: 0;
}
.bgm-input::-moz-range-thumb {
  box-sizing: content-box;
  border: 1px solid #007db5;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}
.bgm-input:active::-moz-range-thumb {
  transform: scale(1.2);
  background: #007db5;
}
.bgm-input::-ms-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: transparent;
  border: solid transparent;
  color: transparent;
}
.bgm-input::-ms-fill-lower {
  background-color: #007db5;
}
.bgm-input::-ms-fill-upper {
  background: linear-gradient(
    to right,
    rgba(0, 125, 181, 0.6) var(--buffered-width),
    rgba(0, 125, 181, 0.2) var(--buffered-width)
  );
}
.bgm-input::-ms-thumb {
  box-sizing: content-box;
  border: 1px solid #007db5;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}
.bgm-input:active::-ms-thumb {
  transform: scale(1.2);
  background: #007db5;
}

.timeline {
  position: absolute;
  bottom: 0px;
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  background-color: #e5e5e5;
  border-radius: 5px;
  background-size: 0% 100%;
  background-image: linear-gradient(#de5e97, #de5e97);
  background-repeat: no-repeat;
  margin-right: var(--space);
}

.timeline::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all 0.1s;
  background-color: #a94672;
}

.timeline::-moz-range-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all 0.1s;
  background-color: #a94672;
}

.timeline::-ms-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all 0.1s;
  background-color: #a94672;
}

.timeline::-webkit-slider-thumb:hover {
  background-color: #943f65;
}

.timeline:hover::-webkit-slider-thumb {
  opacity: 1;
}

.timeline::-moz-range-thumb:hover {
  background-color: #943f65;
}

.timeline:hover::-moz-range-thumb {
  opacity: 1;
}

.timeline::-ms-thumb:hover {
  background-color: #943f65;
}

.timeline:hover::-ms-thumb {
  opacity: 1;
}

.timeline::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.timeline::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.timeline::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.bgm-icon {
  position: absolute;
  display: inline-block;
  display: inline;
  zoom: 1;
  align-items: center;
}
.icon-box {
  position: relative;
  width: 40px;
  height: 40px;
  flex: none;
  order: 2;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
