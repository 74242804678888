.bgm-container {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  display: flex;
  position: relative;
}

.bgm-item {
  box-sizing: border-box;
  width: 100%;
  flex-flow: wrap;
  flex: none;
  order: 0;
  justify-content: center;
  align-items: stretch;
  padding: 4px 0;
  display: flex;
  position: relative;
}

.bgm-box {
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 3px 0 3px 8px;
  display: flex;
}

.bgm-name {
  flex: none;
  order: 0;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.bgm-prod {
  color: gray;
  flex: none;
  order: 1;
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 350;
  line-height: 16px;
}

.bgm-name-box {
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  padding: 0;
  display: flex;
  position: relative;
}

#duration {
  position: absolute;
  right: 0;
}

.bgm-btn {
  cursor: pointer;
  width: 24x;
  height: 24px;
  float: left;
  background: none;
  border: 0;
  outline: none;
  padding: 0;
}

#audio-player-container {
  --seek-before-width: 0%;
  --volume-before-width: 100%;
  --buffered-width: 0%;
  width: 95%;
  max-width: 500px;
  height: 132px;
  background: #fff;
  margin: 100px 2.5% auto;
  position: relative;
}

#audio-player-container:before {
  content: "";
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  background: linear-gradient(to left, #007db5, #ff8a00);
  position: absolute;
  top: -2px;
  left: -2px;
}

.time {
  width: 37px;
  text-align: center;
  flex-shrink: 0;
  font-size: 12px;
  display: inline-block;
}

#volume-slider {
  width: 58%;
  margin: 10px 2.5%;
}

#volume-slider::-webkit-slider-runnable-track {
  background: #007db599;
}

#volume-slider::-moz-range-track {
  background: #007db599;
}

#volume-slider::-ms-fill-upper {
  background: #007db599;
}

#volume-slider:before {
  width: var(--volume-before-width);
}

#mute-icon {
  margin: 0 2.5%;
}

.bgm-input {
  -webkit-appearance: none;
  width: 48%;
  height: 19px;
  float: left;
  outline: none;
  margin: 30px 2.5% 20px;
  padding: 0;
  position: relative;
}

.bgm-input::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: linear-gradient( to right, #007db599 var(--buffered-width), #007db533 var(--buffered-width));
}

.bgm-input:before {
  content: "";
  width: var(--seek-before-width);
  height: 3px;
  cursor: pointer;
  background-color: #007db5;
  position: absolute;
  top: 8px;
  left: 0;
}

.bgm-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-sizing: content-box;
  height: 15px;
  width: 15px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #007db5;
  border-radius: 50%;
  margin: -7px 0 0;
  position: relative;
}

.bgm-input:active::-webkit-slider-thumb {
  background: #007db5;
  transform: scale(1.2);
}

.bgm-input::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: linear-gradient( to right, #007db599 var(--buffered-width), #007db533 var(--buffered-width));
}

.bgm-input::-moz-range-progress {
  background-color: #007db5;
}

.bgm-input::-moz-focus-outer {
  border: 0;
}

.bgm-input::-moz-range-thumb {
  box-sizing: content-box;
  height: 15px;
  width: 15px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #007db5;
  border-radius: 50%;
}

.bgm-input:active::-moz-range-thumb {
  background: #007db5;
  transform: scale(1.2);
}

.bgm-input::-ms-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  color: #0000;
  background: none;
  border: solid #0000;
}

.bgm-input::-ms-fill-lower {
  background-color: #007db5;
}

.bgm-input::-ms-fill-upper {
  background: linear-gradient( to right, #007db599 var(--buffered-width), #007db533 var(--buffered-width));
}

.bgm-input::-ms-thumb {
  box-sizing: content-box;
  height: 15px;
  width: 15px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #007db5;
  border-radius: 50%;
}

.bgm-input:active::-ms-thumb {
  background: #007db5;
  transform: scale(1.2);
}

.timeline {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  margin-right: var(--space);
  background-color: #e5e5e5;
  background-image: linear-gradient(#de5e97, #de5e97);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
}

.timeline::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  cursor: pointer;
  opacity: 0;
  background-color: #a94672;
  border-radius: 50%;
  transition: all .1s;
}

.timeline::-moz-range-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  cursor: pointer;
  opacity: 0;
  background-color: #a94672;
  border-radius: 50%;
  transition: all .1s;
}

.timeline::-ms-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  cursor: pointer;
  opacity: 0;
  background-color: #a94672;
  border-radius: 50%;
  transition: all .1s;
}

.timeline::-webkit-slider-thumb:hover {
  background-color: #943f65;
}

.timeline:hover::-webkit-slider-thumb {
  opacity: 1;
}

.timeline::-moz-range-thumb:hover {
  background-color: #943f65;
}

.timeline:hover::-moz-range-thumb {
  opacity: 1;
}

.timeline::-ms-thumb:hover {
  background-color: #943f65;
}

.timeline:hover::-ms-thumb {
  opacity: 1;
}

.timeline::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  background: none;
  border: none;
}

.timeline::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  background: none;
  border: none;
}

.timeline::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  background: none;
  border: none;
}

.bgm-icon {
  zoom: 1;
  align-items: center;
  display: inline;
  position: absolute;
}

.icon-box {
  width: 40px;
  height: 40px;
  flex: none;
  order: 2;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.bottom-1\/2 {
  bottom: 50%;
}

.right-1\/2 {
  right: 50%;
}

.top-12 {
  top: 3rem;
}

.left-4 {
  left: 1rem;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.top-20 {
  top: 5rem;
}

.right-4 {
  right: 1rem;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-12 {
  bottom: 3rem;
}

.left-1 {
  left: .25rem;
}

.right-1 {
  right: .25rem;
}

.z-50 {
  z-index: 50;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.m-auto {
  margin: auto;
}

.m-2 {
  margin: .5rem;
}

.m-4 {
  margin: 1rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.-my-1 {
  margin-top: -.25rem;
  margin-bottom: -.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mt-1\.5 {
  margin-top: .375rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-1\.5 {
  margin-right: .375rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.ml-3 {
  margin-left: .75rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.h-\[90\%\] {
  height: 90%;
}

.h-full {
  height: 100%;
}

.h-12 {
  height: 3rem;
}

.h-0 {
  height: 0;
}

.h-14 {
  height: 3.5rem;
}

.h-8 {
  height: 2rem;
}

.h-11 {
  height: 2.75rem;
}

.h-1\/2 {
  height: 50%;
}

.h-10 {
  height: 2.5rem;
}

.h-28 {
  height: 7rem;
}

.h-5\/6 {
  height: 83.3333%;
}

.h-36 {
  height: 9rem;
}

.h-16 {
  height: 4rem;
}

.h-6 {
  height: 1.5rem;
}

.h-32 {
  height: 8rem;
}

.h-24 {
  height: 6rem;
}

.h-20 {
  height: 5rem;
}

.h-\[98\%\] {
  height: 98%;
}

.h-\[580px\] {
  height: 580px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[132px\] {
  height: 132px;
}

.max-h-full {
  max-height: 100%;
}

.w-full {
  width: 100%;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-\[940px\] {
  width: 940px;
}

.w-72 {
  width: 18rem;
}

.w-28 {
  width: 7rem;
}

.w-fit {
  width: fit-content;
}

.w-2\/5 {
  width: 40%;
}

.w-8 {
  width: 2rem;
}

.w-screen {
  width: 100vw;
}

.w-2\/4 {
  width: 50%;
}

.w-10 {
  width: 2.5rem;
}

.w-16 {
  width: 4rem;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-80 {
  width: 20rem;
}

.w-36 {
  width: 9rem;
}

.w-11 {
  width: 2.75rem;
}

.w-32 {
  width: 8rem;
}

.w-12 {
  width: 3rem;
}

.w-24 {
  width: 6rem;
}

.w-6 {
  width: 1.5rem;
}

.w-1\/2 {
  width: 50%;
}

.w-20 {
  width: 5rem;
}

.w-\[940\] {
  width: 940px;
}

.w-\[580px\] {
  width: 580px;
}

.w-\[360px\] {
  width: 360px;
}

.w-\[50px\] {
  width: 50px;
}

.min-w-\[206\] {
  min-width: 206px;
}

.min-w-\[228\] {
  min-width: 228px;
}

.min-w-\[100\] {
  min-width: 100px;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-auto {
  flex: auto;
}

.flex-initial {
  flex: 0 auto;
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.basis-0 {
  flex-basis: 0;
}

.basis-5 {
  flex-basis: 1.25rem;
}

.basis-10 {
  flex-basis: 2.5rem;
}

.basis-\[25px\] {
  flex-basis: 25px;
}

.translate-x-1\/2 {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1\/2 {
  --tw-translate-y: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.snap-y {
  scroll-snap-type: y var(--tw-scroll-snap-strictness);
}

.snap-mandatory {
  --tw-scroll-snap-strictness: mandatory;
}

.snap-start {
  scroll-snap-align: start;
}

.list-disc {
  list-style-type: disc;
}

.auto-cols-auto {
  grid-auto-columns: auto;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.content-center {
  align-content: center;
}

.content-end {
  align-content: flex-end;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-center {
  justify-items: center;
}

.gap-2 {
  gap: .5rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-0 {
  gap: 0;
}

.self-center {
  align-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.break-all {
  word-break: break-all;
}

.rounded {
  border-radius: .25rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-t-xl {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.rounded-tl-xl {
  border-top-left-radius: .75rem;
}

.rounded-tr-xl {
  border-top-right-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-r-\[1px\] {
  border-right-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-solid {
  border-style: solid;
}

.border-zinc-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 216 / var(--tw-border-opacity));
}

.border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgb(219 39 119 / var(--tw-border-opacity));
}

.border-zinc-500 {
  --tw-border-opacity: 1;
  border-color: rgb(113 113 122 / var(--tw-border-opacity));
}

.border-\[\#e0e0e0\] {
  --tw-border-opacity: 1;
  border-color: rgb(224 224 224 / var(--tw-border-opacity));
}

.border-error {
  --tw-border-opacity: 1;
  border-color: rgb(251 57 57 / var(--tw-border-opacity));
}

.border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgb(236 72 153 / var(--tw-border-opacity));
}

.border-zinc-100 {
  --tw-border-opacity: 1;
  border-color: rgb(244 244 245 / var(--tw-border-opacity));
}

.border-zinc-200 {
  --tw-border-opacity: 1;
  border-color: rgb(228 228 231 / var(--tw-border-opacity));
}

.border-zinc-400 {
  --tw-border-opacity: 1;
  border-color: rgb(161 161 170 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-neutral-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 212 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgb(220 38 38 / var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 39 119 / var(--tw-bg-opacity));
}

.bg-\[\#EEEEEE\] {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity));
}

.bg-zinc-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 63 70 / var(--tw-bg-opacity));
}

.bg-zinc-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(161 161 170 / var(--tw-bg-opacity));
}

.bg-pink-100\/30 {
  background-color: #fce7f34d;
}

.bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 24 93 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-slate-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}

.bg-zinc-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity));
}

.bg-black\/30 {
  background-color: #0000004d;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-zinc-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(228 228 231 / var(--tw-bg-opacity));
}

.bg-zinc-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 216 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.object-cover {
  object-fit: cover;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-4 {
  padding: 1rem;
}

.p-0 {
  padding: 0;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-\[41\] {
  padding-left: 41px;
  padding-right: 41px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.pt-px {
  padding-top: 1px;
}

.pt-1 {
  padding-top: .25rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-0\.5 {
  padding-left: .125rem;
}

.pl-0 {
  padding-left: 0;
}

.pr-0\.5 {
  padding-right: .125rem;
}

.pr-0 {
  padding-right: 0;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.text-center {
  text-align: center;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.font-semibold {
  font-weight: 600;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.tracking-tighter {
  letter-spacing: -.05em;
}

.text-error {
  --tw-text-opacity: 1;
  color: rgb(251 57 57 / var(--tw-text-opacity));
}

.text-zinc-500 {
  --tw-text-opacity: 1;
  color: rgb(113 113 122 / var(--tw-text-opacity));
}

.text-zinc-400 {
  --tw-text-opacity: 1;
  color: rgb(161 161 170 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-zinc-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 91 / var(--tw-text-opacity));
}

.text-pink-600 {
  --tw-text-opacity: 1;
  color: rgb(219 39 119 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-pink-700 {
  --tw-text-opacity: 1;
  color: rgb(190 24 93 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-info {
  --tw-text-opacity: 1;
  color: rgb(13 110 253 / var(--tw-text-opacity));
}

.text-pink-800 {
  --tw-text-opacity: 1;
  color: rgb(157 23 77 / var(--tw-text-opacity));
}

.text-pink-500 {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity));
}

.text-tag {
  --tw-text-opacity: 1;
  color: rgb(0 55 107 / var(--tw-text-opacity));
}

.text-zinc-300 {
  --tw-text-opacity: 1;
  color: rgb(212 212 216 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-zinc-700 {
  --tw-text-opacity: 1;
  color: rgb(63 63 70 / var(--tw-text-opacity));
}

.text-rose-600 {
  --tw-text-opacity: 1;
  color: rgb(225 29 72 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.head-title {
  font-size: calc(1.325rem + .9vw);
}

body {
  background-color: #fff;
}

@media only screen and (min-width: 1024px) {
  body {
    background-color: #f5f5f5;
  }
}

.container {
  width: 80%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-left: auto;
  margin-right: auto;
}

.createat {
  color: gray;
  font-size: .75rem;
}

.like {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.url {
  position: absolute;
  bottom: 2rem;
  right: 1rem;
}

#URLModal {
  display: none;
}

.profileSpaceContainer {
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.profileSpaceItem {
  width: 33.3333%;
  padding: 10px;
}

.imgBox {
  aspect-ratio: 1 / 1;
  background-size: cover;
}

.itemBtn {
  padding: 10px;
}

.spaces {
  width: 100%;
  height: 100%;
  scroll-snap-type: y mandatory;
  flex-grow: 1;
  overflow-y: auto;
}

.space {
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
  justify-content: center;
  align-items: center;
  display: flex;
}

.inputFontSize {
  font-size: 12px;
}

.bt1 {
  border: 1px solid red;
}

.section {
  height: calc(100% - 58px - 1.25rem);
}

.search-result {
  height: calc(100% - 41px - 1.5rem);
  margin-left: .25rem;
  margin-right: .25rem;
  display: block;
  overflow: auto;
}

.error-body {
  height: 95vh;
  background-color: #f7ebeb;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.error-form {
  text-align: left;
  width: 325px;
  height: fit-content;
  background-color: #fff;
  border-radius: 14px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem 2rem;
  overflow-y: scroll;
}

.error-message {
  color: gray;
  padding: 10px;
  font-size: 14px;
}

.error-btn {
  color: #fff;
  background-color: #db1f72;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 12px;
  text-decoration: none;
}

.alarm-modal {
  height: 400px;
  width: 500px;
  z-index: 999999;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 60px;
  left: calc(50% - 2.5rem);
}

.alarm-modal:after {
  content: " ";
  z-index: 9999;
  border: 15px solid #0000;
  border-top: none;
  border-bottom-color: #fff;
  position: absolute;
  top: -14px;
  left: 356px;
}

.setting-bar {
  width: 242px;
  border-right: 1px solid #e0e0e0;
  border-radius: 5px;
  padding-left: 20px;
}

.setting-label {
  flex-basis: 20%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.setting-input {
  flex-basis: 60%;
}

.placeholder\:text-xs::placeholder {
  font-size: .75rem;
  line-height: 1rem;
}

.placeholder\:text-sm::placeholder {
  font-size: .875rem;
  line-height: 1.25rem;
}

.placeholder\:text-zinc-300::placeholder {
  --tw-text-opacity: 1;
  color: rgb(212 212 216 / var(--tw-text-opacity));
}

.placeholder\:text-zinc-400::placeholder {
  --tw-text-opacity: 1;
  color: rgb(161 161 170 / var(--tw-text-opacity));
}

.placeholder\:text-white::placeholder {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:bg-zinc-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 91 / var(--tw-bg-opacity));
}

.focus\:cursor-pointer:focus {
  cursor: pointer;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:outline-0:focus {
  outline-width: 0;
}

.focus\:outline-1:focus {
  outline-width: 1px;
}

@media (min-width: 640px) {
  .sm\:block {
    display: block;
  }

  .sm\:h-\[600\] {
    height: 600px;
  }

  .sm\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }
}

@media (min-width: 768px) {
  .md\:h-48 {
    height: 12rem;
  }

  .md\:w-48 {
    width: 12rem;
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .lg\:fixed {
    position: fixed;
  }

  .lg\:absolute {
    position: absolute;
  }

  .lg\:relative {
    position: relative;
  }

  .lg\:inset-0 {
    inset: 0;
  }

  .lg\:right-0 {
    right: 0;
  }

  .lg\:top-0 {
    top: 0;
  }

  .lg\:m-auto {
    margin: auto;
  }

  .lg\:m-10 {
    margin: 2.5rem;
  }

  .lg\:m-4 {
    margin: 1rem;
  }

  .lg\:m-0 {
    margin: 0;
  }

  .lg\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .lg\:mt-8 {
    margin-top: 2rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mb-3 {
    margin-bottom: .75rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mt-4 {
    margin-top: 1rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-full {
    height: 100%;
  }

  .lg\:h-\[300\] {
    height: 300px;
  }

  .lg\:h-\[290px\] {
    height: 290px;
  }

  .lg\:w-fit {
    width: fit-content;
  }

  .lg\:w-\[940\] {
    width: 940px;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:w-\[300\] {
    width: 300px;
  }

  .lg\:w-\[70\] {
    width: 70px;
  }

  .lg\:w-28 {
    width: 7rem;
  }

  .lg\:w-\[940px\] {
    width: 940px;
  }

  .lg\:max-w-none {
    max-width: none;
  }

  .lg\:grow-0 {
    flex-grow: 0;
  }

  .lg\:grow {
    flex-grow: 1;
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:gap-4 {
    gap: 1rem;
  }

  .lg\:gap-8 {
    gap: 2rem;
  }

  .lg\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .lg\:p-5 {
    padding: 1.25rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .lg\:pt-11 {
    padding-top: 2.75rem;
  }

  .lg\:pl-20 {
    padding-left: 5rem;
  }

  .lg\:pt-2 {
    padding-top: .5rem;
  }

  .lg\:pb-4 {
    padding-bottom: 1rem;
  }

  .lg\:text-right {
    text-align: right;
  }
}

@media (min-width: 1280px) {
  .xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

.cropper-container {
  direction: ltr;
  -ms-touch-action: none;
  touch-action: none;
  user-select: none;
  font-size: 0;
  line-height: 0;
  position: relative;
}

.cropper-container img {
  height: 100%;
  image-orientation: 0deg;
  width: 100%;
  display: block;
  max-height: none !important;
  max-width: none !important;
  min-height: 0 !important;
  min-width: 0 !important;
}

.cropper-wrap-box, .cropper-canvas, .cropper-drag-box, .cropper-crop-box, .cropper-modal {
  position: absolute;
  inset: 0;
}

.cropper-wrap-box, .cropper-canvas {
  overflow: hidden;
}

.cropper-drag-box {
  opacity: 0;
  background-color: #fff;
}

.cropper-modal {
  opacity: .5;
  background-color: #000;
}

.cropper-view-box {
  height: 100%;
  width: 100%;
  outline: 1px solid #3399ffbf;
  display: block;
  overflow: hidden;
}

.cropper-dashed {
  opacity: .5;
  border: 0 dashed #eee;
  display: block;
  position: absolute;
}

.cropper-dashed.dashed-h {
  height: 33.3333%;
  width: 100%;
  border-top-width: 1px;
  border-bottom-width: 1px;
  top: 33.3333%;
  left: 0;
}

.cropper-dashed.dashed-v {
  height: 100%;
  width: 33.3333%;
  border-left-width: 1px;
  border-right-width: 1px;
  top: 0;
  left: 33.3333%;
}

.cropper-center {
  height: 0;
  opacity: .75;
  width: 0;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.cropper-center:before, .cropper-center:after {
  content: " ";
  background-color: #eee;
  display: block;
  position: absolute;
}

.cropper-center:before {
  height: 1px;
  width: 7px;
  top: 0;
  left: -3px;
}

.cropper-center:after {
  height: 7px;
  width: 1px;
  top: -3px;
  left: 0;
}

.cropper-face, .cropper-line, .cropper-point {
  height: 100%;
  opacity: .1;
  width: 100%;
  display: block;
  position: absolute;
}

.cropper-face {
  background-color: #fff;
  top: 0;
  left: 0;
}

.cropper-line {
  background-color: #39f;
}

.cropper-line.line-e {
  cursor: ew-resize;
  width: 5px;
  top: 0;
  right: -3px;
}

.cropper-line.line-n {
  cursor: ns-resize;
  height: 5px;
  top: -3px;
  left: 0;
}

.cropper-line.line-w {
  cursor: ew-resize;
  width: 5px;
  top: 0;
  left: -3px;
}

.cropper-line.line-s {
  cursor: ns-resize;
  height: 5px;
  bottom: -3px;
  left: 0;
}

.cropper-point {
  height: 5px;
  opacity: .75;
  width: 5px;
  background-color: #39f;
}

.cropper-point.point-e {
  cursor: ew-resize;
  margin-top: -3px;
  top: 50%;
  right: -3px;
}

.cropper-point.point-n {
  cursor: ns-resize;
  margin-left: -3px;
  top: -3px;
  left: 50%;
}

.cropper-point.point-w {
  cursor: ew-resize;
  margin-top: -3px;
  top: 50%;
  left: -3px;
}

.cropper-point.point-s {
  cursor: s-resize;
  margin-left: -3px;
  bottom: -3px;
  left: 50%;
}

.cropper-point.point-ne {
  cursor: nesw-resize;
  top: -3px;
  right: -3px;
}

.cropper-point.point-nw {
  cursor: nwse-resize;
  top: -3px;
  left: -3px;
}

.cropper-point.point-sw {
  cursor: nesw-resize;
  bottom: -3px;
  left: -3px;
}

.cropper-point.point-se {
  cursor: nwse-resize;
  height: 20px;
  opacity: 1;
  width: 20px;
  bottom: -3px;
  right: -3px;
}

@media (min-width: 768px) {
  .cropper-point.point-se {
    height: 15px;
    width: 15px;
  }
}

@media (min-width: 992px) {
  .cropper-point.point-se {
    height: 10px;
    width: 10px;
  }
}

@media (min-width: 1200px) {
  .cropper-point.point-se {
    height: 5px;
    opacity: .75;
    width: 5px;
  }
}

.cropper-point.point-se:before {
  content: " ";
  height: 200%;
  opacity: 0;
  width: 200%;
  background-color: #39f;
  display: block;
  position: absolute;
  bottom: -50%;
  right: -50%;
}

.cropper-invisible {
  opacity: 0;
}

.cropper-bg {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
}

.cropper-hide {
  height: 0;
  width: 0;
  display: block;
  position: absolute;
}

.cropper-hidden {
  display: none !important;
}

.cropper-move {
  cursor: move;
}

.cropper-crop {
  cursor: crosshair;
}

.cropper-disabled .cropper-drag-box, .cropper-disabled .cropper-face, .cropper-disabled .cropper-line, .cropper-disabled .cropper-point {
  cursor: not-allowed;
}

/*# sourceMappingURL=index.50ec1788.css.map */
